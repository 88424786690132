/*======================================
=         13. Gallery Css             =
=======================================*/
.single-gallery-wrap {
    margin-top: 30px;
    & a {
        display: block;
        position: relative;
        & img {
            width: 100%;
        }
        &:after{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            color: $white;
            content: 'View';
            font-weight: 600;
            background: $theme-color--default;
            border-radius: 50%;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
        }
        &:hover{
            &:after{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}