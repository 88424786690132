
/*=========================================
=               05. Footer Css            =
===========================================*/

.footer-bottom-area {
    border-top: 1px solid #707070;
    & .copy-right-box {
        display: flex;
        justify-content: space-between;
        & p {
            margin-top: 20px;
        }
        @media #{$small-mobile}{
            flex-direction: column;
            justify-content: center;
            text-align: center;
            flex-direction: column-reverse;
            & p {
                &:first-child {
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.bg-footer {
    background: #1a1a1a;
}


.footer-title {
    position: relative;
    margin-bottom: 40px;
    h6 {
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 0px;
        padding-bottom: 15px;
        font-family: $font-family-name;
    }
    &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 50px;
        height: 3px;
        background: $white;
        transition: all 0.3s ease-in-out;
    }
}

.footer-contents {
    ul {
        & li {
            margin-bottom: 15px;
        }
    }
        
    .newsletter-box {
        position: relative;
        input {
            background: #fff;
            width: 100%;
            border: none;
            padding: 8px 50px 8px 10px;
            color: #333;
            border-radius: 25px;
            position: relative;
        }
        button {
            background: #fff;
            background: transparent;
            color: #333;
            position: absolute;
            right: 0px;
            border: none;
            font-size: 20px;
            padding: 0px 17px;
            top: 4px;
            &:hover {
                color: $theme-color--default;
            }
        }
    }
    
  
}


.footer-social-share {
    & li {
        display: inline-block;
        margin-right: 20px;
        font-size: 30px;
    }
}



.copyright-text{
    display: inline-block;
    @media #{$large-mobile}{
        margin-bottom: 15px;
    }
}

/*=====  End of footer ======*/
