/*=======================================
=            10. Feature Css           =
=========================================*/

.single-feature-wrap {
    margin-top: 30px;
    & .feature-icon {
        margin-bottom: 25px;
    }
    & .feature-content {
        padding: 0 12px;
        & .feature-title {
            margin-bottom: 15px;
        }
    }
}

.single-feature-two {
    margin-top: 30px;
    box-shadow: 0 0px 1px rgba(112, 112, 112, 0.3);
    border-radius: 5px;
    padding: 20px;
    transition: all 0.4s ease-in-out;
    & .feature-icon {
        margin-bottom: 25px;
    }
    & .feature-content {
        padding: 0 12px;
        & .feature-title {
            margin-bottom: 15px;
        }
    }
    &:hover {
        box-shadow: 0 2px 6px rgba(112, 112, 112, 0.3);
    }
}